.inner-page {
    padding-top: rem-calc(80);
}

body.navbar-white {
    padding-top: rem-calc(80);
    @media (max-width: 1399px) {
        padding-top: rem-calc(70);
    }
    @media (max-width: 1199px) {
        padding-top: rem-calc(70);
    }
    @media (max-width: 991px) {
        padding-top: rem-calc(70);
    }
    @media (max-width: 576px) {
        padding-top: rem-calc(62);
    }
}

.rotate-270 {
    transform: rotate(270deg);
}

.faq-search {
    margin-bottom: rem-calc(80);
    .form-group {
        position: relative;
        .form-control {
            padding-right: rem-calc(40);
        }
    }
    .button-submit {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        svg {
            transform: rotate(270deg);
        }
    }
}

.cta-wrap-multi {
    margin-top: rem-calc(70);
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
        min-width: 220px;
        margin: rem-calc(5) rem-calc(25)
    }
}

.intro-wrap {
    .note {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: rem-calc(70);
        // text-transform: uppercase;
        font-weight: 400;
        font-size: rem-calc(30);
    }
    h2 {
        // text-transform: uppercase;
    }
}

.review-carousel-nodots {
    .owl-dots {
        display: none;
    }
}

.review-wrap {
    &.left {
        background-position: left center;
        h2 {
            margin-left: rem-calc(60);
        }
        .review-text {
            margin-right: 0;
            margin-left: rem-calc(60);
        }
        .review-item .caption {
            display: flex;
            justify-content: flex-end;
            margin-left: unset;
            margin-right: rem-calc(44);
        }
    }
}

@media (max-width:991px){
    .cta-wrap-multi {
        flex-direction: column;
        .btn {
            width: 100%;
            max-width: 300px;
        }
    }
    .review-wrap.left {
        .review-text,
        h2 {
            margin-left: 0;
        }
    }
    .review-wrap .review-item .caption {
        color: $color-darker;
        display: flex;
        justify-content: flex-start !important;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width:575px){
    .cta-wrap-multi {
        .btn {
            max-width: unset;
        }
    }
}