.navbar {
  &:not(.scrolled),
  &:not(.navbar-white) {
    .contact-wrap .btn-tel,
    .navtop-search .btn-search {
      color: $color-dark-grey;
    }
  }
  &.scrolled,
  &.navbar-white {
    .contact-wrap .btn-tel,
    .navtop-search .btn-search {
      color: $color-dark-grey;
    }
  }
  .phone-mobile {
    display: none;
  }
}
.breadcrumbs {
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  * {
    font-size: rem-calc(14);
  }
  &-link {
    color: $color-dark-grey;
    display: inline-block;
    position: relative;
    margin-right: rem-calc(20);
    &::after {
      content: "/";
      position: absolute;
      right: -15px;
      top: 0;
      pointer-events: none;
    }
    &:hover {
      color: $color-darker;
      text-decoration: none;
      &::after {
        color: $color-dark-grey;
      }
    }
  }
}
.blog {
  &-filter {
    margin-bottom: rem-calc(40);
    // margin-left: rem-calc(-10);
    // margin-right: rem-calc(-10);
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    li {
      margin: rem-calc(5);
      button {
        display: block;
        color: $color-primary;
        text-decoration: none;
        font-size: rem-calc(16);
        font-weight: 400;
        line-height: 1;
        padding: 8px 20px;
        line-height: calc(24 / 16);
        border: solid 1px $color-primary;
        border-radius: 10rem;
        background-color: transparent;
        transition: all 0.2s linear;
        text-transform: unset;

        &:hover {
          color: #fff;
          background: $color-primary;
        }
        &.active {
          color: #fff;
          background: $color-primary;
        }
      }
    }
  }
  &-pagination {
    margin-top: rem-calc(20);
    ul {
      padding: 0;
      margin-bottom: rem-calc(10);
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      li {
        a {
          font-size: rem-calc(16);
          border-radius: 20rem;
          margin: rem-calc(5);
          color: #000;
          display: block;
          text-decoration: none;
          &.active {
            color: #fff;
            background: $color-pastel;
          }
        }
        &:not(.prev):not(.next) {
          a {
            height: 30px;
            min-width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.prev {
          margin-right: rem-calc(10);
        }
        &.next {
          margin-left: rem-calc(10);
        }
      }
    }
  }
}

.articles-masonry-item {
  &.pink {
    .article-content * {
      color: #fff;
    }
    .article-wrap:after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ffffff'/%3E%3C/svg%3E")
        no-repeat;
      background-position: 50%;
    }
  }
  /*.article-content {
        small {
            color:#fff;
        }
    }*/
  &.brown {
    .article-content * {
      @extend .text-brown;
    }
    .article-wrap:after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23D1CCBD'/%3E%3C/svg%3E")
        no-repeat;
      background-position: 50%;
    }
  }
  &.blue {
    .article-content * {
      @extend .text-blue;
    }
    .article-wrap:after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23ABC7C9'/%3E%3C/svg%3E")
        no-repeat;
      background-position: 50%;
    }
  }
  &.green {
    .article-content * {
      @extend .text-white;
    }
    .article-wrap:after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%23fff'/%3E%3C/svg%3E")
        no-repeat;
      background-position: 50%;
    }
  }
  &.darker {
    .article-content * {
      @extend .text-darker;
    }
    .article-wrap:after {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.89.96l-1.054 1.08 3.21 3.21H0v1.5h15.047l-3.211 3.21 1.055 1.08L17.93 6 12.89.96z' fill='%2300313C'/%3E%3C/svg%3E")
        no-repeat;
      background-position: 50%;
    }
  }
}

a {
  &:not([class]) {
    text-decoration: underline;
  }
  &:hover {
    color: $color-primary;
  }
}
.elementbenefitscontent {
  .section {
    &:not(.grey-bg):not(.brown-bg) {
      .textside {
        background: #e9e7e1;
      }
    }
  }
}
.section {
  &.brown-bg {
    .accordion {
      &.accordion-white {
        .accordion-header {
          a:not(.collapsed) {
            color: #fff;
            background: $color-pastel;
          }
        }
        .accordion-content {
          &-inner {
            padding: 1.875rem;
            background: #fff;
          }
        }
      }
    }
  }
}

.intro-wrap {
  img {
    max-width: 100%;
    height: auto;
  }
}

.navbar {
  text-transform: unset;
}

.bootstrap-select > select {
  height: 52px !important;
}

.userform-dropdown {
  min-height: 52px;
}

.dropdown-menu.show {
  .dropdown-menu.inner {
    display: block !important;
  }
}

.search-item {
  padding: rem-calc(35) 0;
  border-bottom: solid 1px rgba($color-brown, 0.5);
  h4 {
    font-size: rem-calc(30);
    margin-bottom: rem-calc(20);
    a {
      color: $color-darker;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  p {
    margin-bottom: rem-calc(25);
  }
  .link {
    font-size: rem-calc(17);
    color: $color-pastel;
    text-decoration: underline;
  }
}

.gallery-masonsry {
  &-wrap {
  }
  &-item {
    width: calc(100% / 3);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    a {
      display: block;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.waving {
  color: #fff;
  svg {
    width: 100%;
    height: auto;
  }
  &.brown-bg {
    background-color: unset;
    color: #f8f7f8;
  }
  &.grey-bg {
    background-color: unset;
    color: #e9e7e1;
  }
  &.bg {
    &-darkder {
      background-color: unset;
      color: $color-darker;
    }
    &-pastel,
    &-pink {
      background-color: unset;
      color: $color-pastel;
    }
    &-blue {
      background-color: unset;
      color: $color-blue;
    }
    &-brown {
      background-color: unset;
      color: $color-brown;
    }
    &-green {
      background-color: unset;
      color: $color-green;
    }
  }
}

@keyframes drawing {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1550;
  }
}

[data-aos="draw"] {
  &.aos-animate {
    .path-shadow {
      animation: drawing 2s ease-in-out forwards;
    }
  }
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}

.navbar-collapse {
  justify-content: center;
  .navbar-nav {
    display: flex;
    align-items: center;
    .nav-item {
      .nav-link {
        font-size: rem-calc(16);
        padding: 0 rem-calc(10);
        margin-left: rem-calc(10);
        margin-right: rem-calc(10);
        &.logo {
          @media (min-width: 1400px) {
            margin-left: rem-calc(30);
            margin-right: rem-calc(30);
          }
        }
      }
    }
  }
  .nav-aside {
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    @media (min-width: 992px) {
      position: static;
      transform: unset;
    }
  }
  @media (max-width: 1599px) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          margin-left: rem-calc(5);
          margin-right: rem-calc(5);
        }
      }
    }
  }
  @media (max-width: 1399px) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          font-size: rem-calc(15);
          &.logo {
            svg {
              width: 200px;
              height: 67px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1199px) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          &.logo {
            svg {
              width: 150px;
              height: 47px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .navbar-nav {
      display: none;
    }
  }
}

header {
  position: relative;
  .header-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 55%;
    height: 100%;
    z-index: 5;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // background-color: $color-info;
    padding-left: rem-calc(40);
    &::before,
    &::after {
      content: "";

      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::before {
      width: 100%;
      background-image: url("../img/v-divider.svg");
      background-repeat: no-repeat;
      background-position: 100% -5px;
      background-size: cover;
      z-index: 1;
    }
    &::after {
      width: 50%;
      background-color: $color-info;
      z-index: 0;
    }
    & > * {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem-calc(50);
      margin-bottom: rem-calc(20);
      text-transform: uppercase;
      max-width: 70%;
      color: $color-primary;
      span {
        font-style: italic;
      }
    }
    p {
      max-width: 58%;
      margin-bottom: rem-calc(35);
    }
  }
  @media (max-width: 991px) {
    .header-content {
      width: calc(100% - 150px);
      &::after {
        width: 30%;
      }

      h1 {
        font-size: rem-calc(35);
        margin-bottom: rem-calc(15);
      }
      p {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(15);
      }
    }
  }
  @media (max-width: 767px) {
    .header-content {
      padding-bottom: 0;
      padding-left: rem-calc(20);
      left: 0;
      width: calc(100% - 80px);
      &::before {
        background-size: calc(100% + 50px);
      }
    }
  }
  @media (max-width: 576px) {
    .header-content {
      width: calc(100% - 20px);
      &::before {
        background-size: cover;
        background-position: 100% 2px;
      }
    }
  }
  &.small-size {
    .header-content {
      /* bottom: unset;
            top: 50px; */
    }
    .big-header-carousel {
      .header-carousel .owl-stage-outer:after {
        /*visibility: hidden;*/
      }
    }
  }
}

.feature-carousel {
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-img {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem-calc(30);
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    p {
      font-size: rem-calc(20);
      font-weight: 500;
      //max-width: 182px;
      color: $color-dark-grey;
      text-transform: unset;
    }
  }
  .item-grid {
    row-gap: rem-calc(50);
    @media (max-width: 991px) {
      row-gap: rem-calc(30);
    }
    .item {
      margin: 0;
      flex-direction: row;
      column-gap: rem-calc(15);
      text-align: left;
      .item-img {
        width: 60px;
        height: 60px;
        margin: 0;
        flex-shrink: 0;
      }
    }
    p {
      margin: 0;
    }
  }
}

.weatherwidget-io {
  position: fixed !important;
  right: 0;
  top: 120px;
  width: 110px;
  z-index: 4;
  transform: scale(0.7);
  opacity: 1;
  transition: opacity 0.2s ease-out;
  transform-origin: top right;
  &.scrolled {
    opacity: 0;
    pointer-events: none;
  }
  @media (max-width: 1399px) {
    top: 107px;
  }
  @media (max-width: 1199px) {
    top: 87px;
  }
  @media (max-width: 991px) {
    top: unset;
    bottom: 0;
    transform: scale(0.7);
    transform-origin: bottom right;
    &.scrolled {
      opacity: 0;
    }
  }
}

body:not(.homepage) .weatherwidget-io {
  display: none !important;
}

.section-img {
  position: relative;
  @media (max-width: 991px) {
    padding-top: 0 !important;
  }
}

.image-text {
  // position: relative;
  & > .image {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
    border-radius: 300px 300px 0px 0px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 400;
    width: 100%;
    span {
      font-weight: 400 !important;
    }
    h6 {
      display: inline-block;
      font-size: rem-cacl(14);
      color: $color-dark-grey;
      margin-bottom: rem-calc(15);
    }
    h2 {
      font-size: rem-calc(42);
      line-height: calc(52 / 42);
      color: $color-primary;
      margin-bottom: rem-calc(40);
      max-width: 500px;
      @media (max-width: 991px) {
        font-size: rem-calc(30);
        margin-bottom: rem-calc(25);
      }
    }
    p {
      max-width: 600px;
      color: $color-dark-grey;
    }
    ul {
      padding: 0;
      margin: 0;
      margin-bottom: rem-calc(10);
      list-style: none;
      li {
        padding-left: rem-calc(30);
        position: relative;
        margin-bottom: rem-calc(15);
        color: #333333;
        &::marker {
          display: none;
          content: none;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%2351B9BA'%2F%3E%3C%2Fsvg%3E")
            no-repeat;
        }
      }
    }
    &-padding {
      padding-left: rem-calc(60);
      @media (max-width: 991px) {
        padding-left: 0;
      }
    }
    &-padding-right {
      padding-right: rem-calc(60);
      @media (max-width: 991px) {
        padding-right: 0;
      }
    }
    // ul{
    //     li{
    //         position: relative;
    //         &::marker{
    //             content: "";
    //         }
    //         &::before{
    //             content: "";
    //             background: $color-darker;
    //             width: 5px;
    //             height: 5px;
    //             border-radius: 50%;
    //             position: absolute;
    //             left: rem-calc(-10);
    //             top: rem-calc(10);
    //             display: block;
    //         }
    //     }
    // }
  }
  .image-side {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
    }
    .inner {
      position: relative;
      //   padding-bottom: rem-calc(60);
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      @media (max-width: 991px) {
        padding-bottom: rem-calc(30);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 2;
      }
      &::before {
        content: "";
        background-color: $color-primary;
        position: absolute;
        bottom: rem-calc(20);
        width: 85%;
        height: 80%;
        z-index: 1;
        display: none;
      }
    }
    &.left {
      @media (min-width: 992px) {
        right: 0;
      }
      .inner {
        justify-content: flex-start;
        &::before {
          right: 0;
          border-bottom-right-radius: rem-calc(35);
          @media (max-width: 767px) {
            border-bottom-right-radius: rem-calc(20);
          }
        }
      }
    }
    &:not(.left) {
      @media (min-width: 992px) {
        left: 0;
      }
      .inner {
        justify-content: flex-end;
        &::before {
          left: 0;
          border-bottom-left-radius: rem-calc(35);
          @media (max-width: 767px) {
            border-bottom-left-radius: rem-calc(20);
          }
        }
      }
    }
  }

  &.left {
    & > .image {
      left: 0;
    }
  }
  &.right {
    & > .image {
      right: 0;
    }
  }
}

.community-carousel,
.home-carousel {
  .slick-slide {
    margin: 0 15px;
    height: inherit !important;
  }
  .slick-list {
    margin: 0 -15px;
  }
  .slick-track {
    display: flex !important;
  }
}

.darker-bg {
  background: $color-light-blue;
}

.testimonial {
  &-wrap {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(60);
    @media (max-width: 767px) {
      padding-top: rem-calc(40);
      padding-bottom: rem-calc(40);
    }
  }
  &-carousel {
    margin-bottom: 0;
    padding-bottom: rem-calc(80);
    border-bottom: dashed 2px $color-light;
    @media (max-width: 991px) {
      padding-bottom: rem-calc(45);
    }
    .owl-dots {
      display: none;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      .owl-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba($color-pastel, 0.4);
        margin: rem-calc(10);
        transition: background-color 0.2s ease-out;
        &.active,
        &:hover {
          background: $color-pastel;
        }
      }
    }
    .owl-nav {
      display: flex;
      column-gap: rem-calc(10);
      button.owl-prev,
      button.owl-next {
        width: 36px;
        height: 36px;
        border: solid 1px $color-dark-grey !important;
        border-radius: 50%;
        background-color: $color-dark-grey !important;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'11'%20viewBox%3D'0%200%2018%2011'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M5.10938%2010.5591L6.16406%209.48096L2.95313%206.27002L18%206.27002L18%204.77002L2.95313%204.77002L6.16406%201.55908L5.10938%200.480956L0.0703129%205.52002L5.10938%2010.5591Z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        span {
          display: none;
        }
      }
      button.owl-next {
        transform: rotate(180deg);
      }
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .name {
      font-size: rem-calc(20);
      display: inline-block;
      margin-bottom: rem-calc(20);
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: rem-calc(24);
      margin-bottom: rem-calc(40);
      .rate {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: rem-calc(4);
        svg {
          color: $color-light;
        }
      }
      .date {
        font-size: rem-calc(16);
      }
    }
  }
  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: rem-calc(15);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    text-align: left;
    font-size: rem-calc(24);
    font-weight: 300;
    margin-bottom: rem-calc(30);
    max-width: 700px;
    @media (max-width: 767px) {
      font-size: rem-calc(25);
    }
  }
}

.testimonial-wrap {
  &.darker-bg {
    /*.testimonial-text {
            color: #fff;
        }
        */
  }
}

.cta-block {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  justify-content: flex-start;
  @media (min-width: 992px) {
    min-height: 570px;
  }
  .img-side {
    width: 60%;
    position: relative;
    flex-shrink: 0;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text-side {
    // background-color: $color-secondary;
    // background-image: url("../img/pattern.svg");
    // background-position: 50%;
    // background-repeat: repeat-x;
    // background-size: auto 100%;
    width: 40%;
    padding: rem-calc(70) rem-calc(60);
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // color: $color-white;
    position: relative;
    &::before,
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      pointer-events: none;
      user-select: none;
    }
    &::after {
      width: 70%;
      left: 0;
      background-color: $color-info;
      z-index: 0;
    }
    &::before {
      width: 100%;
      left: 50%;
      background-image: url("../img/v-divider.svg");
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: cover;
      z-index: 1;
    }
    & > * {
      position: relative;
      z-index: 2;
    }
    h2 {
      font-size: rem-calc(42);
      margin-bottom: rem-calc(20);
      // color: $color-white;
    }
    p {
      margin-bottom: rem-calc(35);
      // color: $color-white;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    .img-side {
      width: 100%;
      height: auto;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 70%;
        background-image: url("../img/v-divider.svg");
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
        z-index: 1;
      }
      & > img {
        width: 100%;
        height: auto;
      }
    }
    .text-side {
      width: 100%;
      padding: rem-calc(50) rem-calc(25);
      background-color: $color-info;
      &::before,
      &::after {
        display: none;
      }
      h2 {
        font-size: rem-calc(30);
        margin-bottom: rem-calc(15);
      }
      p {
        margin-bottom: rem-calc(20);
      }
    }
  }
  // &::before {
  //      content: '';
  //      position: absolute;
  //      top: 0;
  //      left: 0;
  //      width: 100% !important;
  //      height: 100%;
  //      z-index: 1;
  //      background-color: rgba(#fff,.6);
  //     //  background: url('../img/header-shadow.svg') no-repeat left top;
  //     //  background-size: cover;
  // }
}

.big-header.home-design {
  .big-header-carousel {
    .slick-list {
      &::after {
        display: none;
      }
    }
  }
}

.home-designs-page {
  .home-designs-icon-block {
    .section {
      padding-bottom: 0px;
    }
  }
}

.container {
  &.narrow {
    max-width: 600px;
  }
}

.hide {
  display: none;
}

@media (min-width: 1200px) {
  .col-xl.dv-5 {
    flex: 0 0 calc(100% / 5);
  }
}

body.homedesign {
  .big-header.home-design .slick-header-carousel:after {
    display: none;
  }
}
body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.embed-wrap {
  & > iframe {
    width: 100%;
    max-height: calc(100vh - 92px);
    @media (max-width: 576px) {
      max-height: calc(100vh - 81px);
    }
  }
}
body.no-layout {
  padding-top: 0;
  .fixed-top,
  .subscribe,
  .footer {
    display: none;
  }
  .embed-wrap {
    & > iframe {
      max-height: calc(100vh - 23px);
      @media (max-width: 576px) {
        max-height: calc(100vh - 23px);
      }
    }
  }
}

.item-thumb-carousel {
  .thumb-tag {
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 3px;
    background: $color-green;
    olor: $color-dark-grey;
    font-weight: 300;
    padding: rem-calc(2) rem-calc(10);
    z-index: 1;
  }
  .slick-thumb-carousel,
  .slick-thumb-nav {
    width: auto;
    height: 100%;
    .slick-item {
      &-inner {
        width: 100%;
        padding-bottom: 60%;
        position: relative;
        overflow: hidden;
        & > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .slick-thumb-carousel {
    .slick-prev,
    .slick-next {
      position: absolute;
      top: calc(50% - 18px);
      z-index: 99;
      background: $color-green;
      border: 0px;
      color: $color-dark-grey;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
    .slick {
      &-track {
        display: flex !important;
      }
      &-slide {
        height: inherit !important;
      }
    }
  }

  .slick-thumb-nav {
    @media (max-width: 991px) {
      margin-left: -5px;
      margin-right: -5px;
    }
    .slick {
      &-item {
        margin-bottom: rem-calc(12);
        @media (max-width: 991px) {
          margin-bottom: 0;
          padding-left: 5px;
          padding-right: 5px;
        }
        &.slick-current {
          .slick-item-inner {
            position: relative;
            &::before {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              //border: solid 2px $color-darker;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.home {
  &-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 991px) {
      margin-bottom: rem-calc(30);
    }
    .h2 {
      margin-bottom: rem-calc(15);
    }
    .style {
      margin-bottom: rem-calc(30);
      font-weight: 400;
      span {
        font-weight: 300;
        text-transform: unset;
      }
      @media (max-width: 991px) {
        margin-bottom: rem-calc(20);
      }
    }
    .feature-list {
      margin-bottom: rem-calc(30);
      padding-top: rem-calc(11);
      padding-bottom: rem-calc(11);
      border-top: 1px solid #cecece;
      border-bottom: 1px solid #cecece;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: rem-calc(8);
        }
        & + span {
          margin-left: rem-calc(40);
        }
      }
      @media (max-width: 991px) {
        margin-bottom: rem-calc(20);
        padding-top: rem-calc(8);
        padding-bottom: rem-calc(8);
        span {
          & + span {
            margin-left: rem-calc(22);
          }
        }
      }
    }
    &-inner {
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-pastel;
        margin-bottom: rem-calc(30);
        @media (max-width: 991px) {
          margin-bottom: rem-calc(15);
        }
      }
      h2 {
        @extend .h3;
      }
    }
  }
  &-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    padding: rem-calc(30) rem-calc(50);
    border-radius: rem-calc(10);
    .h3 {
      margin-bottom: rem-calc(20);
      color: $color-darker;
      font-weight: 300;
    }
    h5 {
      font-size: rem-calc(18);
      font-weight: 400;
      //text-decoration: underline;
      text-underline-offset: 2px;
      margin-bottom: rem-calc(10);
    }
    p {
      margin-bottom: 0;
    }
    .btn-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: rem-calc(35);
      .btn {
        width: 100%;
        & + .btn {
          margin-top: rem-calc(15);
        }
      }
    }
    @media (max-width: 991px) {
      padding: rem-calc(20) rem-calc(30);
      .btn-wrap {
        margin-top: rem-calc(20);
        .btn {
          & + .btn {
            margin-top: rem-calc(10);
          }
        }
      }
    }
  }
  &-floorplan {
    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      .sub {
        //text-transform: unset;
        font-weight: 400;
        margin-bottom: rem-calc(8);
        color: $color-pastel;
      }
      .h3 {
        margin-bottom: rem-calc(20);
      }
      p {
        margin-bottom: 0;
      }
      .btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: rem-calc(35);
        .btn {
          width: 100%;
          & + .btn {
            margin-top: rem-calc(15);
          }
        }
      }
    }
    @media (max-width: 991px) {
      &-text {
        margin-bottom: rem-calc(30);
        .btn-wrap {
          margin-top: rem-calc(20);
          .btn {
            & + .btn {
              margin-top: rem-calc(10);
            }
          }
        }
      }
    }
  }
}

.new-arrow {
  .ct-slick {
    color: $color-darker;
    background: white;
    width: 50px;
    border: solid 1px $color-darker;
    height: 24px;
    border-radius: 10rem;
    &.slick-disabled {
      opacity: 0.3;
    }
  }
}

.homefinder {
  position: relative;
  z-index: 3;
}

.custom {
  &-image-card-inner {
    background: none;
    padding-left: 0;
    padding-right: 0;
    h3 {
      margin-bottom: 0;
      text-transform: uppercase;
    }
    span {
      display: inline-block;
      border: 1px solid $color-pastel;
      color: $color-pastel;
      border-radius: rem-calc(5);
      padding: rem-calc(2) rem-calc(8);
      font-weight: 400;
      font-size: rem-calc(13);
    }
  }
  &-image-card-link {
    background: none !important;
    border-radius: rem-calc(5);
  }
  &-featured-post {
    background: #fff;
    border: 1px solid #e9e7e1;
  }
  &-image-featured-post {
    border-radius: rem-calc(5) rem-calc(5) 0 0;
  }
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc(10);
}

.positon-index {
  position: relative;
  z-index: 2;
}

.has-marker {
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    pointer-events: none;
    background-image: url("../img/v-overlay.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    z-index: 2;
  }
}

// .btn-play {
//   width: 56px;
//   height: 56px;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: $color-white;
//   border: 0;
//   backdrop-filter: blur(6px);
//   background-color: rgba(47, 47, 47, 0.4);
//   transition: background 0.2s ease-out;
//   svg {
//     margin-left: rem-calc(4);
//   }
//   &:hover,
//   &:focus {
//     background-color: rgba(47, 47, 47, 0.7);
//   }
// }

.vimeo,
.youtube {
  &-row {
    row-gap: rem-calc(30);
  }
  &-btn {
    display: block;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    & > .images {
      margin-bottom: rem-calc(15);
    }
    .height {
      margin-bottom: 0;
    }
    .btn-play {
      width: 60px;
      height: 60px;
      min-height: unset;
      object-fit: unset;
      display: block;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      padding: 0;
      background: transparent;
      border: none;
      img {
        width: 100%;
        height: 100%;
        position: static;
        transform: unset;
      }
    }
  }
}
