/* #region Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,600&display=swap");
/* #endregion */

/* #region Variables */
$color-info: #daebf3;
$color-warning: #0c5ca5;
$color-darker: #000000;
$color-base: #000000;
$color-dark: #000000;
$color-dark-grey: #54636b;
$color-dark-blue: #6b7f91;

$color-pastel: #4db9b8;
$color-blue: #4db9b8;
$color-brown: #f5f5f7;
$color-green: #daebf3;
$color-grey: #daebf3;
$color-light-blue: rgba(101, 123, 140, 0.3);

$color-primary: #4db9b8;
$color-secondary: #0c5ca5;
$color-light: #ffffff;
$color-white: #ffffff;
$color-divider: #daebf3;
/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $color-dark-grey;
  overflow-x: hidden;
  position: relative;
}
p {
  font-weight: 400;
}
a {
  font-weight: 400;
}
* {
  outline: none !important;
}
/* #endregion */

/* #region Typography */
h1,
h2,
h3,
h4 .h1,
.h2,
.h3,
.h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color:$color-primary;
}
h5,
h6,
.h5,
.h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: unset;
  color:$color-primary;
}
.h1,
h1 {
  font-size: rem-calc(50);
  line-height: 120%;
}
.h2,
h2 {
  font-size: rem-calc(36);
  line-height: 128%;
  margin-bottom: rem-calc(20);
}
.h3,
h3 {
  font-size: rem-calc(24);
  margin-bottom: rem-calc(20);
}
.h4,
h4 {
  line-height: 1.33334;
  margin-bottom: rem-calc(18);
}
p {
  margin-bottom: rem-calc(24);
}
ul,
ol {
  li {
    font-size: 1rem;
  }
}
p,
ul li,
ol li,
a {
  color: $color-dark-grey;
}

[type="button"],
[type="submit"],
button {
  -webkit-appearance: none;
}
/* #endregion */
.bg {
  &-darkder {
    background-color: $color-darker;
  }
  &-pastel {
    background-color: $color-pastel;
  }
  &-pink {
    background-color: $color-pastel;
  }
  &-blue {
    background-color: $color-darker;
  }
  &-brown {
    background-color: $color-brown;
  }
  &-green {
    background-color: $color-green;
  }
  &-grey {
    background-color: $color-grey;
  }
  &-light-blue {
    background-color: $color-light-blue;
  }
}

.text {
  &-white {
    color: #fff;
  }
  &-darker {
    color: $color-darker !important;
  }
  &-pastel {
    color: $color-pastel;
  }
  &-pink {
    color: $color-pastel;
  }
  &-blue {
    color: $color-blue;
  }
  &-brown {
    color: $color-brown;
  }
  &-green {
    color: $color-green;
  }
  &-primary {
    color: $color-primary !important;
  }
  &-secondary {
    color: $color-secondary !important;
  }
}

.fw-300 {
  font-weight: 300 !important;
}
.fz-34 {
  font-size: rem-calc(34) !important;
  font-weight:bold;
  @media (max-width: 991px) {
    font-size: rem-calc(20) !important;
    font-weight:bold;
  }
}
